import React from "react"
import styled from "styled-components"
import { rem, fluidRange } from "polished"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { cardBorders, shadow } from "../utils/styles"
import SEO from "../containers/seo"
import _Layout, { Main } from "../containers/layout"
import _Hero, {
  Content as HeroContent,
  ImageWrapper as HeroImageWrapper,
} from "../components/hero"
import Badge from "../components/badge"
import _IconList, { List, ListItem, Size } from "../components/lists/icon-list"
import _Clients from "../containers/clients"
import heroImage from "../images/illustrations/travel-services-hero.svg"
import pageBackground from "../images/backgrounds/page.svg"
import cometariSygnet from "../images/icons/light/cometari-sygnet.svg"

export default function TravelServicesPage({ data }) {
  return (
    <Layout>
      <SEO title="Travel Services" />
      <Hero
        title="travel software"
        subtitle={
          <>
            Innovative digital technology for <strong>travel industry</strong>
          </>
        }
        text="Cometari team specializes in developing travel software. Our mission is to provide innovative and cost effective solutions for the travel industry. We build and support travel software applications specifically for the travel & hospitality sector. We have carried out a lot of projects for Sabre which helped us gain hands-on experience of the industry and confidence we can handle even the most challenging tasks."
        image={<img src={heroImage} alt="airplane" />}
      />
      <Clients />
      <Partnership
        title="partnership"
        subtitle="Cometari is Sabre Authorized Developer"
        text="Here you will learn how travel industry expertise and the solid knowledge in the travel tech field of Cometari team can combine to create exciting and innovative opportunities having the potential to improve your business. If you are looking for a reliable technology partner that immediately understands your travel business, Cometari is the right choice."
        image={<Img fluid={data.sabreAuthorizedDev.childImageSharp.fluid} />}
        showContactButton={false}
      />
      <Section>
        <SectionTitle>services</SectionTitle>
        <SectionSubtitle>Sabre Web Services</SectionSubtitle>
        <SectionText>
          Cometari solutions allow you to seamlessly connect your application to
          multiple travel suppliers at once. Our experience in integrating
          various travel APIs allows you to build a web-optimized and
          mobile-friendly travel application in fraction of time, cost and
          complexity. A comprehensive set of travel APIs that enable our clients
          to build unique travel solutions, enabling faster time to market.
        </SectionText>
        <IconList
          icon={<img src={cometariSygnet} alt="Cometari Sygnet" />}
          title="Solutions"
        >
          <List>
            <ListItem>
              Integrate the Largest Travel Suppliers on the Market
            </ListItem>
            <ListItem>Affiliations and Reselling</ListItem>
            <ListItem>Expand Travel Inventory</ListItem>
          </List>
        </IconList>
      </Section>
      <Section>
        <SectionTitle>services</SectionTitle>
        <SectionSubtitle>Red Apps</SectionSubtitle>
        <SectionText>
          A thorough analysis of your business can help us create New Red App
          application for your business. Sabre Red Apps are approved
          applications that can be added to your Sabre Red Workspace to advance
          the potential of Sabre Red. Sabre Red App owners have a complete
          control over the sale and distribution of their applications through
          the Sabre Red App Centre. These Sabre Red applications have the
          ability to fully integrate an infinite variety of processes and
          services directly into travel agent's desktop.
        </SectionText>
        <IconList
          icon={<img src={cometariSygnet} alt="Cometari Sygnet" />}
          title="Solutions"
        >
          <List>
            <ListItem>Red App Initial Design</ListItem>
            <ListItem>Workflow Design</ListItem>
            <ListItem>GUI Design and Implementation</ListItem>
            <ListItem>
              Transformation of scribe script into fully functional Red App.
            </ListItem>
            <ListItem>Solution Architecture</ListItem>
            <ListItem>Red App Development</ListItem>
            <ListItem>Content Integration</ListItem>
          </List>
        </IconList>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TravelServices {
    sabreAuthorizedDev: file(
      absolutePath: { regex: "/illustrations/sabre-authorized-developer.png$/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Hero = styled(_Hero)`
  grid-column: full;
`
const Clients = styled(_Clients)`
  grid-column: full;
  max-width: 100%;
  overflow: unset; /* This prevents bug in Firefox with CSS Grid */

  @media screen and (max-width: ${rem("1260px")}) {
    border-radius: 0;
  }

  @media screen and (min-width: ${rem("1260px")}) {
    grid-column: 3 / span 10;
  }
`
const Partnership = styled(Hero)`
  grid-column: main;
  padding: ${rem("100px")} 0;
  color: ${props => props.theme.color.textLight};
  background-color: ${props => props.theme.color.bgDark};

  @media screen and (min-width: ${rem("1200px")}) {
    ${HeroImageWrapper} {
      grid-column: 3 / span 3;
    }

    ${HeroContent} {
      grid-column: 7 / span 5;
    }
  }
`
const IconList = styled(_IconList).attrs({ size: Size.SMALL })`
  color: ${props => props.theme.color.text};

  ${List} {
    @media screen and (max-width: ${rem("1199px")}) {
      column-count: auto;
    }
  }

  ${ListItem} {
    ${cardBorders()}
    ${shadow()}

    padding: ${rem("25px")} ${rem("10px")};
    background-color: ${props => props.theme.color.bgLight};
    opacity: 1;
  }
`
const SectionTitle = styled(Badge)`
  max-width: ${rem("107px")};
`
const SectionSubtitle = styled.p`
  margin: ${rem("6px")} 0 0;
  font-weight: 500;

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "28px",
      toSize: "54px",
    },
    "400px",
    "1000px",
  )}
`
const SectionText = styled.p`
  line-height: 1.5;
  font-size: ${rem("16px")};
  font-weight: 300;
  margin: ${rem("48px")} 0;

  @media screen and (min-width: ${rem("1200px")}) {
    margin: 0;
  }
`
const Section = styled.section`
  grid-column: full;
  display: inherit;
  grid-template-columns: inherit;
  column-gap: inherit;
  align-items: start;
  text-align: center;

  ${SectionTitle},
  ${SectionSubtitle},
  ${SectionText},
  ${IconList} {
    grid-column: main;
    justify-self: center;

    @media screen and (max-width: ${rem("1199px")}) {
      max-width: 40em;
    }
  }

  ${SectionTitle} {
    justify-self: center;
  }

  @media screen and (min-width: ${rem("1200px")}) {
    grid-template-rows: auto minmax(auto, 1fr) ${rem("75px")} repeat(2, auto);
    text-align: left;

    ${SectionTitle},
    ${SectionSubtitle} {
      justify-self: start;
    }

    ${SectionTitle} {
      grid-column: 4 / 6;
      grid-row: 1;
    }

    ${SectionSubtitle} {
      grid-column: 4 / span 3;
      grid-row: 2;
    }

    ${SectionText} {
      grid-column: 7 / span 5;
      grid-row: 1 / 3;
    }

    ${IconList} {
      grid-column: 4 / span 8;
      grid-row: 4 / 6;
    }
  }
`
const Layout = styled(_Layout)`
  background-image: ${`url(${pageBackground})`};
  background-repeat: no-repeat;
  background-position: 0 30px;

  ${Main} {
    background-image: ${`url(${pageBackground})`};
    background-repeat: repeat-x;
    background-position: bottom -200px left 0;

    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "32px",
        toSize: "64px",
      },
      "400px",
      "1000px",
    )}
    ${fluidRange(
      {
        prop: "paddingBottom",
        fromSize: "64px",
        toSize: "128px",
      },
      "400px",
      "1000px",
    )}
  }
`
